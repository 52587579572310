import React from "react";
import sampleCharacterizarion from "../static/assets/sample-characterizarion.png";
import sampleWeight from "../static/assets/sample-weight.png";
import sampleReport from "../static/assets/sample-report.png";
import sampleContainers from "../static/assets/sample-containers.png";
import sampleActivity from "../static/assets/sample-activity.png";
import sampleCaracteristic from "../static/assets/sample-caracteristic.png";
import { useTranslation } from "react-i18next";
import { FaLockOpen } from "react-icons/fa";

const ForbiddenModule = ({ viewType }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <section className="w-full flex flex-col bg-white p-3 rounded-lg my-2 items-center">
        <h3 className="flex justify-center text-center font-semibold w-2/3 ml-2 mt-3">
          <FaLockOpen style={{ marginRight: 3 }} />
          {viewType === "monitoring"
            ? t("Save 30% on your waste management costs")
            : t(
                "Generate regulatory characterization reports complying with AGEC Decree 1199",
              )}
        </h3>
        <span>
          {t("Contact us at")}{" "}
          <span className="text-[#0F265C]">client@akanthas.com</span>{" "}
          {t("for more information")}.
        </span>
      </section>
      <section className="w-full flex flex-col bg-white p-3 rounded-lg my-2 items-center">
        <div className="flex flex-col w-5/6 border-b items-center">
          <h1 className="w-full text-center font-bold text-[#F8AA00]">
            {viewType === "monitoring" ? "20" : "80"}%
          </h1>
          <span className="mb-4">
            {viewType === "monitoring"
              ? t(
                  "Improve the quality of your waste streams by reducing sorting errors by 20%",
                )
              : t(
                  "Get your characterization reports at 80% less cost than traditional methods",
                )}
          </span>
        </div>
        <div className="w-full flex flex-col items-center">
          <h3 className="text-center font-bold mt-4 mb-4">
            {viewType === "monitoring"
              ? t("Top waste monitoring features")
              : t("Top characterization features")}
          </h3>
          <div className="w-full flex flex-row gap-4">
            <div className="w-1/3 flex flex-col p-3 text-center">
              {viewType === "monitoring" ? (
                <>
                  <img alt="img" src={sampleContainers} />
                  <h5 className="text-center font-bold my-3">
                    {t("Real-Time Monitoring")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Track the fill levels of your containers and storage cells in real time",
                    )}
                    . {t("All your information, all in one place")}.
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={sampleCharacterizarion} />
                  <h5 className="text-center font-bold my-3">
                    {t("Visualize Characterized Waste Catergories")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "View your characterized waste streams and display the detected material types one by one",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
            <div className="w-1/3 flex flex-col p-3">
              {viewType === "monitoring" ? (
                <>
                  <img alt="img" src={sampleActivity} />
                  <h5 className="text-center font-bold my-3">
                    {t("Quality Scoring & Downgrade Potential")}
                  </h5>
                  <span className="text-center px-2">
                    {t("Review quality scores and monitor downgrade risks")}.{" "}
                    {t(
                      "Access historical data on fill levels and sorting errors",
                    )}
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={sampleWeight} />
                  <h5 className="text-center font-bold my-3">
                    {t("Input Weighing Tickets")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Enter weighing tickets and customize characterizations to your specific needs, including material flows, sorting challenges, comments, and producer names",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
            <div className="w-1/3 flex flex-col p-3">
              {viewType === "monitoring" ? (
                <>
                  <img alt="img" src={sampleCaracteristic} />
                  <h5 className="text-center font-bold my-3">
                    {t("Automated Sorting Error Alerts & Collection Requests")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Customize sorting error types and collection thresholds",
                    )}
                    .{" "}
                    {t(
                      "Automatically notify your team or service providers in real time",
                    )}
                    .
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={sampleReport} />
                  <h5 className="text-center font-bold my-3">
                    {t("Export Regulatory Reports")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Automatically export your characterization reports in the format required by regulations",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForbiddenModule;
