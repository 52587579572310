import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import Cookies from "js-cookie";
import { getShortIdPart } from "../components/formatted/FormattedText";
import { FaClock, FaExpand } from "react-icons/fa";

const AppInstallationDetails = () => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const { id } = useParams();
  const [leftImageUrl, setLeftImageUrl] = useState("");
  const [imageDatetime, setImageDatetime] = useState("");
  const [zoomedImage, setZoomedImage] = useState("");

  function formatDateTime(datetime) {
    const dateObj = new Date(datetime);
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const handleZoom = (side) => {
    if (side === "left") {
      setZoomedImage(leftImageUrl);
    } else {
      setZoomedImage(leftImageUrl.replace("-L", "-R"));
    }
  };

  const fetchCycleLogAndImageList = async () => {
    await axios
      .all([
        axios.get(
          `${API}azurecyclelogandimage/${id.replace("_r", "").replace("_l", "")}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          },
        ),
      ])
      .then(
        axios.spread((response) => {
          if (response.status === 200) {
            const recentLogEntry = response.data
              .filter((item) => item.log && (item.leftImage || item.rightImage))
              .reduce((latest, current) => {
                return new Date(latest.matchingCrontabDateTime) >
                  new Date(current.matchingCrontabDateTime)
                  ? latest
                  : current; // Comparer les dates et garder le plus récent
              }, response.data[0]);

            setImageDatetime(
              recentLogEntry.name.split("-camera")[0].replace(/_/g, ":") +
                ".000Z",
            );
            setLeftImageUrl(
              "https://cameraanalysis.blob.core.windows.net/camera-analysis/camera-" +
                id.replace("_l", "") +
                "/raw/" +
                recentLogEntry.name +
                "-L-raw.jpeg?sp=racwdl&st=2022-07-26T12:47:17Z&se=2032-04-01T20:47:17Z&spr=https&sv=2021-06-08&sr=c&sig=s%2BcYHrtVj4IStYaCALzDcMLmoDsJH%2B6FfBFpCYHOBO4%3D",
            );
          }
        }),
      );
  };

  useEffect(() => {
    fetchCycleLogAndImageList();
  });

  return (
    <div>
      <AppHeaderBar title={"Installation - ak_" + getShortIdPart(id)} />
      <div className="mobileapp-container">
        <div className="datetime-box">
          <FaClock className="clock-icon" />
          <p className="datetime-text">
            Image taken at {formatDateTime(imageDatetime)}
          </p>
        </div>
        <div className="image-container">
          <div className="image-item" onClick={() => handleZoom("left")}>
            <img src={leftImageUrl} className="device-image" alt="" />
            <FaExpand className="expand-icon" />
            {!leftImageUrl && <div className="placeholder">Loading...</div>}
          </div>
          <div className="image-item" onClick={() => handleZoom("right")}>
            <img
              src={leftImageUrl.replace("-L", "-R")}
              className="device-image"
              alt=""
            />
            <FaExpand className="expand-icon" />
            {!leftImageUrl && <div className="placeholder">Loading...</div>}
          </div>
        </div>

        {zoomedImage ? (
          <img src={zoomedImage} alt="" style={{ marginTop: "8px" }} />
        ) : (
          <div className="zoom-placeholder">
            Click on the miniature to zoom in
          </div>
        )}
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppInstallationDetails;
