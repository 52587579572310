import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { FaExclamationTriangle, FaTruck, FaCompress } from "react-icons/fa";
import { checkIfMobile } from "../components/mobileHelpers";

const notifications = [
  {
    id: 1,
    type: "error",
    icon: <FaExclamationTriangle />,
    title: "10 erreurs de tri",
    description: "Incinérable - Déchetterie de Spernot",
    date: "28/09/2024 - 14:30",
    read: false,
  },
  {
    id: 2,
    type: "error",
    icon: <FaExclamationTriangle />,
    title: "10 erreurs de tri",
    description: "Incinérable - Déchetterie de Spernot",
    date: "28/09/2024 - 14:30",
    read: true,
  },
  {
    id: 3,
    type: "collect",
    icon: <FaTruck />,
    title: "Niveau de remplissage max",
    description: "Incinérable - Déchetterie de Spernot",
    date: "28/09/2024 - 15:00",
    read: false,
  },
  {
    id: 4,
    type: "compacting",
    icon: <FaCompress />,
    title: "Compactage à réaliser",
    description: "Incinérable - Déchetterie de Spernot",
    date: "28/09/2024 - 16:15",
    read: true,
  },
  {
    id: 5,
    type: "error",
    icon: <FaExclamationTriangle />,
    title: "10 erreurs de tri",
    description: "Incinérable - Déchetterie de Spernot",
    date: "28/09/2024 - 14:30",
    read: true,
  },
];

const NotificationRow = ({ notification }) => {
  const history = useHistory();

  const styles = {
    row: {
      display: "flex",
      cursor: "pointer",
      padding: "10px",
      borderBottom: "1px solid #ddd",
    },
    icon: {
      marginRight: "10px",
    },
    content: {
      flex: 1,
    },
    date: {
      fontSize: "12px",
      color: "#888",
    },
  };

  const handleClick = () => {
    history.push(`/app/notificationsdetails`, {
      id: notification.id,
      title: notification.title,
      description: notification.description,
      date: notification.date,
    });
  };

  return (
    <div className="notification-row" onClick={handleClick} style={styles.row}>
      <div className="notification-icon">{notification.icon}</div>
      <div className="notification-content">
        <span className="notification-date">{notification.date}</span>
        <div className="notification-details">
          <h3>{notification.title}</h3>
          <p>{notification.description}</p>
        </div>
      </div>
    </div>
  );
};

const AppNotifications = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div>
      <AppHeaderBar title="Notifications" />
      <div className="mobileapp-container">
        <div className="notifications-list">
          {notifications.map((notification) => (
            <NotificationRow
              key={notification.id}
              notification={notification}
            />
          ))}
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppNotifications;
