import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

// Login
import ConfidentialityAgreement from "./containers/login/ConfidentialityAgreement";
import ForgotPassword from "./containers/login/ForgotPassword";
import Login from "./containers/login/Login";
import ResetPasswordByMail from "./containers/login/ResetPasswordByMail";

// Pages
import Activity from "./containers/activity/Activity";
import Address from "./containers/address/Address";
import Camera from "./containers/camera/Camera";
import CharacterizationsView from "./containers/container/CharacterizationsView";
import ChartCamera from "./containers/camera/ChartCamera";
import Container from "./containers/container/Container";
import ContainerDetailsView from "./containers/container/ContainerDetailsView";
import Dashboard from "./containers/dashboard/Dashboard";
import DigitalTwin from "./containers/digitalTwin/DigitalTwin";
import Help from "./containers/help/Help";
import Settings from "./containers/settings/Settings";
import Statistics from "./containers/statistics/Statistics";
import UserAccess from "./containers/userAccess/UserAccess";
import Waste from "./containers/waste/Waste";

// App
import AppAccount from "./mobileapp/AppAccount";
import AppHome from "./mobileapp/AppHome";
import AppInstallation from "./mobileapp/AppInstallation";
import AppInstallationDetails from "./mobileapp/AppInstallationDetails";
import AppNotifications from "./mobileapp/AppNotifications";
import AppNotificationsDetails from "./mobileapp/AppNotificationsDetails";
import AppSettings from "./mobileapp/AppSettings";

// Utils
import Header from "./components/Header";
import NavigationBar from "./components/NavigationBar";

// Styles
import "./App.css";
import "./static/styles/akanthas.scss";

// Maps
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/leaflet.markercluster.js";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationFR from "./locales/fr/translation.json";
import { getContainersOptions } from "./services/container";
import { fetchUserLanguage } from "./redux/languageSlice";
import { useDispatch } from "react-redux";
import MaskLicenseAlert from "./components/MaskLicenseAlert";
import { getCorporationData } from "./services/corporation";
import { checkIfMobile } from "./components/mobileHelpers";

const App = () => {
  const API = process.env.REACT_APP_API_BACKEND;
  const dispatch = useDispatch();
  const [token, setToken] = useState(Cookies.get("userToken") || null);
  const [firstName, setFirstName] = useState(Cookies.get("firstName") || null);
  const [userAdmin, setUserAdmin] = useState(Cookies.get("userAdmin") || null);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null,
  );
  const [licenseExpired, setLicenseExpired] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIfMobile());

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const containersOptions = JSON.parse(
    localStorage.getItem("containersOptions"),
  );

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  const cookieSideNavExpandedValue = Cookies.get("sideNavExpanded");
  const parsedSideNavExpandedValue = cookieSideNavExpandedValue
    ? JSON.parse(cookieSideNavExpandedValue)
    : true;
  const [sideNavExpanded, setSideNavExpanded] = useState(
    parsedSideNavExpandedValue,
  );
  const contentStyle = {
    marginLeft: sideNavExpanded ? "200px" : "60px",
    transition: "margin 0.2s ease",
  };

  const setCookies = (tokenToSet, adminToSet, firstName, userEmail) => {
    if (tokenToSet) {
      Cookies.set("userToken", tokenToSet, { secure: true });
      Cookies.set("userAdmin", adminToSet, { secure: true });
      Cookies.set("firstName", firstName, { secure: true });
      Cookies.set("userEmail", userEmail, { secure: true });
      setToken(tokenToSet);
      setUserAdmin(adminToSet);
      setFirstName(firstName);
    } else {
      Cookies.remove("userToken");
      Cookies.remove("userAdmin");
      Cookies.remove("firstName");
      Cookies.remove("userEmail");
      setToken(null);
      setUserAdmin(null);
      setFirstName(null);
    }
  };

  const saveUser = async (userToSave) => {
    if (userToSave) {
      localStorage.setItem("user", JSON.stringify(userToSave));
      setUser(userToSave);
    } else {
      localStorage.removeItem("user");
      setUser(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user && user._id) {
        dispatch(fetchUserLanguage(user._id));
      }
    };
    fetchData();
  }, [user, dispatch]);

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
      fr: {
        translation: translationFR,
      },
    },
    lng: localStorage.getItem("language") || "fr", // default language
    fallbackLng: i18n.language || "fr", // fallback language
    interpolation: {
      escapeValue: false,
    },
  });

  const fetchContainersOptions = () => {
    getContainersOptions().then((response) => {
      if (response.status === 200) {
        localStorage.setItem(
          "containersOptions",
          JSON.stringify(response.data),
        );
      }
    });
  };

  const fetchFormattedMaterial = () => {
    axios
      .get(`${API}material`, {
        headers: { authorization: "Bearer " + token },
      })
      .then((responseMaterialsDescription) => {
        if (
          responseMaterialsDescription.status >= 200 &&
          responseMaterialsDescription.status < 400
        ) {
          sessionStorage.setItem(
            "materialsDescription",
            JSON.stringify(responseMaterialsDescription.data.materialList),
          );
        }
      });
  };

  useEffect(() => {
    if (token) {
      if (!containersOptions) {
        fetchContainersOptions();
      }
      fetchFormattedMaterial();
    }
  });

  useEffect(() => {
    if (token) {
      getCorporationData(user).then((responseCorporation) => {
        if (
          responseCorporation.status >= 200 &&
          responseCorporation.status < 400
        ) {
          let licenseIsExpired =
            responseCorporation?.data?.corporation?.licenseExpired || false;
          setLicenseExpired(licenseIsExpired);
          if (licenseIsExpired && !isAkanthasAccount) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "auto";
          }
        }
      });
    }
  }, [user, token, setLicenseExpired, isAkanthasAccount]);

  return (
    <Router>
      <main>
        {token && !isMobile && (
          <>
            <NavigationBar
              setSideNavExpanded={setSideNavExpanded}
              sideNavExpanded={sideNavExpanded}
            />

            <div className="app-container" style={contentStyle}>
              <Header
                setCookies={setCookies}
                saveUser={saveUser}
                userData={user}
                licenseExpired={licenseExpired}
              />

              {licenseExpired && !isAkanthasAccount && <MaskLicenseAlert />}

              <Switch>
                <Route path="/access">
                  <UserAccess userAdmin={userAdmin} />
                </Route>

                <Route path="/activity">
                  <Activity />
                </Route>

                <Route path="/address">
                  <Address token={token} API={API} />
                </Route>

                <Route path="/cameras">
                  <Camera />
                </Route>

                <Route path="/camera/:id">
                  <ChartCamera token={token} API={API} />
                </Route>

                <Route path="/containers">
                  <Container token={token} API={API} />
                </Route>

                <Route path="/container/:id">
                  <ContainerDetailsView token={token} API={API} />
                </Route>

                <Route path="/characterizations">
                  <CharacterizationsView token={token} API={API} />
                </Route>

                <Route path="/digitaltwins">
                  <DigitalTwin />
                </Route>

                <Route path="/help">
                  <Help />
                </Route>

                <Route path="/statistics">
                  <Statistics token={token} API={API} />
                </Route>

                <Route path="/settings">
                  <Settings token={token} API={API} />
                </Route>

                <Route path="/waste">
                  <Waste token={token} API={API} />
                </Route>

                <Route path="/">
                  <Dashboard token={token} API={API} firstName={firstName} />
                </Route>
              </Switch>
            </div>
          </>
        )}

        {!token && (
          <>
            <Switch>
              <Route path="/password/updatebymail/:token">
                <ResetPasswordByMail API={API} />
              </Route>

              <Route path="/login">
                <Login setCookies={setCookies} saveUser={saveUser} API={API} />
              </Route>

              <Route path="/confidentialityagreement">
                <ConfidentialityAgreement />
              </Route>

              <Route path="/forgotpassword">
                <ForgotPassword API={API} />
              </Route>

              <Route path="*">
                <Redirect to="/login" API={API} />
              </Route>
            </Switch>
          </>
        )}

        {token && isMobile && (
          <>
            <Switch>
              <Route path="/app/account">
                <AppAccount setCookies={setCookies} saveUser={saveUser} />
              </Route>

              <Route path="/app/home">
                <AppHome />
              </Route>

              <Route path="/app/installation">
                <AppInstallation />
              </Route>

              <Route path="/app/installationdetails/:id">
                <AppInstallationDetails />
              </Route>

              <Route path="/app/notifications">
                <AppNotifications />
              </Route>

              <Route path="/app/notificationsdetails">
                <AppNotificationsDetails />
              </Route>

              <Route path="/app/settings">
                <AppSettings />
              </Route>

              <Route path="*">
                <Redirect to="/app/home" />
              </Route>
            </Switch>
          </>
        )}
      </main>
    </Router>
  );
};

export default App;
