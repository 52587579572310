import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import myPackmatIcon from "../../static/assets/CompactingIcon.jpg";
import {
  elapsedTimeContainer,
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import {
  formattedLevelColor,
  formattedTypeContainer,
  toFrenchNumber,
} from "../../components/formatted/FormattedText";
import ActivityTimeLineTooltip from "./ActivityTooltip";
import { useDispatch } from "react-redux";
import { toggleCollection } from "../../redux/collectionSlice";
import {
  getHistoryCycleContainer,
  getAzureBlob,
  saveContainerWeight,
  updateOutputSurface,
  getTemporalData,
} from "../../services/container";
import { getCameraId } from "../../shared/utils/utils";
import { CropImage } from "../../components/utils/ImageTreatment";
import { useTranslation } from "react-i18next";
import ContainerModalClient from "../container/containerView/ContainerModalClient";
import { StarRatingIcon } from "../container/ContainerIcons";
import ActivityCollectionFileModal from "./ActivityCollectionFileModal";

const ActivityCollectionRow = ({
  timeLine,
  index,
  setModalHistoryCycle,
  setDataRow,
  loadScreen,
  handleCsvExport,
  wasteCategory,
  superContainers,
  setActiveWasteCategory,
  activeWasteCategory,
  weightToTonnes,
}) => {
  const { t, i18n } = useTranslation();
  const azureUrl = process.env.REACT_APP_AZURE_URL;
  const [itemIndex, setItemIndex] = useState(null);
  const [historyCycle, setHistoryCycle] = useState([]);
  const [numberOfPackmat, setNumberOfPackmat] = useState(0);
  const [numberOfStars, setNumberOfStars] = useState(3);
  const [numberOfAnomaly, setNumberOfAnomaly] = useState(0);
  const [weight, setWeight] = useState(null);
  const [wasteCategoryName, setWasteCategoryName] = useState("");
  const [wasteName, setWasteName] = useState(timeLine.wasteName);

  const [displayWeight, setDisplayWeight] = useState(false);
  const [displayClient, setDisplayClient] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [temporalData, setTemporalData] = useState([]);
  const [helpWeight, setHelpWeight] = useState(false);
  const [outputClient, setOutputClient] = useState({});
  const [totalVolumeSortingError, setTotalVolumeSortingError] = useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const weightRef = useRef(null);
  const dispatch = useDispatch();
  const imageWidth = 150;
  const imageHeight = 150;

  const toggleDisplayWeight = () => {
    setDisplayWeight(!displayWeight);
    setDisplayClient(true);
    setNewClient(false);
  };

  const handleSelectionChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setClientName(selectedValue?.name);
    setClientCode(selectedValue?.code);
    setClientAddress(selectedValue?.address);
  };

  const fetchData = async () => {
    await getHistoryCycleContainer(
      {
        currentCycle: false,
        getLevelLastRotation: false,
        containerCollectionDate: timeLine._time,
      },
      timeLine._measurement,
    ).then((responseHistoryCycle) => {
      if (responseHistoryCycle.status === 200) {
        setHistoryCycle(responseHistoryCycle.data.historyCycle);
        setNumberOfPackmat(responseHistoryCycle.data.numberOfPackmat);
        setNumberOfStars(responseHistoryCycle.data.numberOfStars);
        setNumberOfAnomaly(responseHistoryCycle.data.numberOfAnomaly);
        setTotalVolumeSortingError(
          responseHistoryCycle.data.totalVolumeSortingError,
        );
        setTotalWasteVolume(responseHistoryCycle.data.totalWasteVolume);
        const historyData = {
          historyCurrent: responseHistoryCycle.data.historyCycle,
          numberOfAnomaly: responseHistoryCycle.data.numberOfAnomaly,
          numberOfStars: responseHistoryCycle.data.numberOfStars,
          anomalyDelivery: responseHistoryCycle.data.anomalyDelivery,
          numberOfPackmat: responseHistoryCycle.data.numberOfPackmat,
          totalVolumeSortingError:
            responseHistoryCycle.data.totalVolumeSortingError,
          totalWasteVolume: responseHistoryCycle.data.totalWasteVolume,
          lastPackmatEfficiency:
            responseHistoryCycle?.data?.lastPackmatEfficiency.length > 0
              ? responseHistoryCycle.data.lastPackmatEfficiency[0]
              : null,
        };
        dispatch(
          toggleCollection({
            [`${timeLine._measurement}_${timeLine._time}`]: historyData,
          }),
        );
      }
    });
  };

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line
    [timeLine._time, timeLine._measurement],
  );

  const fetchTemporalData = useCallback(() => {
    getTemporalData({ time: timeLine._time }, timeLine._id).then(
      (responseTemporalData) => {
        if (responseTemporalData.status === 200) {
          setTemporalData(
            responseTemporalData.data.temporalData.length > 0 &&
              responseTemporalData.data.temporalData[0],
          );
          setLoading(false);
        }
      },
    );
  }, [timeLine]);

  useEffect(() => {
    fetchTemporalData();
  }, [fetchTemporalData]);

  const fetchWasteCategory = async () => {
    setLoading(true);
    let id = timeLine._id;
    if (Object.keys(superContainers).length > 0) {
      id = superContainers._id;
    }
    const cameraId = getCameraId(timeLine.image_url);
    const blobName = `${"camera-ak_" + cameraId}/output-client/${timeLine._time
      .replace(/:/g, "_")
      .slice(0, -1)}-${"camera-ak_" + cameraId}-${id}-client.json`;
    const blobUrl = azureUrl + blobName;
    try {
      await getAzureBlob({ blobUrl: blobUrl }).then((response) => {
        if (Object.keys(response.data).length > 0) {
          setOutputClient(response.data);
          setWeight(response?.data?.weight / 1000);
          if (
            response?.data?.waste_category &&
            response?.data?.waste_category !== ""
          ) {
            setClientName(
              response?.data?.client
                ? JSON.parse(response?.data?.client).name
                : "",
            );
            setClientCode(
              response?.data?.client
                ? JSON.parse(response?.data?.client).code
                : "",
            );
            setClientAddress(
              response?.data?.client
                ? JSON.parse(response?.data?.client).address
                : "",
            );
            setWasteCategoryName(response?.data?.waste_category);
            setWasteName(
              JSON.parse(response?.data?.waste_category).wasteCategoryName,
            );
            setFiles(
              response?.data?.client &&
                JSON.parse(response?.data?.client)?.files
                ? JSON.parse(response?.data?.client).files
                : [],
            );
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(
    () => {
      fetchWasteCategory();
    },
    // eslint-disable-next-line
    [timeLine, azureUrl, superContainers],
  );

  const saveOutputSurface = async (waste_category) => {
    if (waste_category !== null) {
      setLoading(true);
      const cameraId = getCameraId(timeLine.image_url);
      const wasteCategory = JSON.parse(waste_category);
      const promises = historyCycle.map(async (history) => {
        let newObjectSegmentations = {};
        let params = {
          waste_category_label: wasteCategory.wasteCategoryName,
          waste_category_value: wasteCategory.wasteCategoryValue,
          waste_category_code: wasteCategory.wasteCategoryCode,
          waste_category_density: wasteCategory.wasteCategoryDensity,
          waste_category_authorized_materials:
            wasteCategory.wasteCategoryAuthorizedMaterial,
          containerId: timeLine._id,
          time: history._time,
        };
        const outputSurfaceBlob = `camera-ak_${cameraId}/output-surface/${history._time.replace(/:/g, "_").slice(0, -1)}-camera-ak_${cameraId}-${timeLine._id}-surface.json`;
        const blobUrl = azureUrl + outputSurfaceBlob;

        try {
          const azureResponse = await getAzureBlob({ blobUrl: blobUrl });
          let outputSurface = azureResponse.data;
          newObjectSegmentations = outputSurface.object_segmentations.map(
            (item) => ({
              ...item,
              isAuthorizedMaterial:
                wasteCategory.wasteCategoryAuthorizedMaterial.some(
                  (material) => material.value === item.cls,
                ),
            }),
          );

          const anomaliesCount = newObjectSegmentations.filter(
            (material) => !material.isAuthorizedMaterial,
          ).length;

          params = {
            ...outputSurface,
            ...params,
            object_segmentations: newObjectSegmentations,
            anomalies: anomaliesCount,
          };
        } catch (error) {
          if (error.response && error.response.status === 404) {
            newObjectSegmentations = history.anomaliesAnnotation.map(
              (item) => ({
                ...item,
                isAuthorizedMaterial:
                  wasteCategory.wasteCategoryAuthorizedMaterial.some(
                    (material) => material.value === item.cls,
                  ),
              }),
            );

            const anomaliesCount = newObjectSegmentations.filter(
              (material) => !material.isAuthorizedMaterial,
            ).length;

            params = {
              ...params,
              object_segmentations: newObjectSegmentations,
              anomalies: anomaliesCount,
            };
          } else {
            console.error(error);
          }
        }
        const data = {
          data: params,
          blobName: outputSurfaceBlob,
        };
        await updateOutputSurface(data);
      });

      await Promise.all(promises);
    }
  };

  const handleSubmit = async (waste_category, changeWasteCategory) => {
    if (changeWasteCategory) {
      setWasteName(JSON.parse(waste_category).wasteCategoryName);
    } else if (wasteCategoryName !== "") {
      setWasteName(JSON.parse(wasteCategoryName).wasteCategoryName);
    }
    if (waste_category !== null || !changeWasteCategory) {
      let id = timeLine._id;
      if (Object.keys(superContainers).length > 0) {
        id = superContainers._id;
      }
      const cameraId = getCameraId(timeLine.image_url);
      const blobName = `${
        "camera-ak_" + cameraId
      }/output-client/${timeLine._time.replace(/:/g, "_").slice(0, -1)}-${
        "camera-ak_" + cameraId
      }-${id}-client.json`;
      const blobUrl = azureUrl + blobName;
      try {
        const azureResponse = await getAzureBlob({ blobUrl: blobUrl });
        let data = azureResponse.data;
        data = {
          ...data,
          ...{
            weight: parseFloat(String(weight).replace(",", ".")) * 1000,
            time: timeLine._time,
            modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            client: `{"name": "${clientName}", "code": "${clientCode}", "address": "${clientAddress}", "files": ${files}}`,
            waste_category: changeWasteCategory
              ? waste_category
              : wasteCategoryName,
            blobName: blobName,
          },
        };
        await saveContainerWeight(data);
      } catch (error) {
        if (error.response.status === 404) {
          await saveContainerWeight({
            id: id,
            weight: parseFloat(String(weight).replace(",", ".")) * 1000,
            time: timeLine._time,
            modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            client: `{"name": "${clientName}", "code": "${clientCode}", "address": "${clientAddress}", "files": ${files}}`,
            waste_category: changeWasteCategory
              ? waste_category
              : wasteCategoryName,
            blobName: blobName,
          });
        } else {
          console.error(error);
        }
      }
      if (!changeWasteCategory) {
        await saveOutputSurface(wasteCategoryName);
      }
      await fetchData();
      await fetchWasteCategory();
      setDisplayWeight(!displayWeight);
      setDisplayClient(false);
      setNewClient(false);
      setTotalWeight(weight);
      setLoading(false);
    }
  };

  return (
    <section className="time-line-element" key={index}>
      <div className="time-line-item">
        <div style={{ marginLeft: "25px", marginRight: "25px" }}>
          {timeLine.image_url && (
            <CropImage
              container={temporalData}
              data={temporalData}
              imgDivWidth={imageWidth}
              imgDivHeight={imageHeight}
              isTooltip={false}
              isMap={false}
              isCharacterization={false}
              displayLegend={false}
              materialsDropdownToDisplay={[]}
              showCheckbox={false}
            />
          )}
        </div>

        <div className="time-line-column-data time-line-data-large time-line-data-bloc-hide">
          <span className="time-line-data-bold">
            {elapsedTimeContainer(timeLine._time, i18n.language, true)}
          </span>
          <span className="data-column">
            {formattedDate(timeLine._time, i18n.language)},{" "}
            {formattedTime(timeLine._time)}
          </span>
        </div>
        <div className="time-line-column-data  time-line-data-large time-line-data-bloc-hide">
          <span className="time-line-data-bold">{timeLine.positioning}</span>
          <span className="time-line-data-bold">{timeLine.addressName}</span>
        </div>
        {/* Waste category */}
        <div className="time-line-data time-line-data-large time-line-data-bloc-hide">
          {loading || (historyCycle && historyCycle.length < 1) ? (
            <div role="status" className="flex justify-center w-full">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <select
              id="client"
              class="bg-gray-50 border-0 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
              onChange={(event) => {
                setWasteCategoryName(event.target.value);
                saveOutputSurface(event.target.value);
                handleSubmit(event.target.value, true);
              }}
            >
              <option value={null} className="text-gray-300">
                Flux
              </option>
              {wasteCategory.map((item) => {
                const isSelected = wasteName === item.label;
                const value = {
                  id: item._id,
                  wasteCategoryName: item.label,
                  wasteCategoryValue: item.value,
                  wasteCategoryCode: item.wasteCodeFull,
                  wasteCategoryAuthorizedMaterial: item.authorizedMaterial,
                  wasteCategoryDensity: item.density,
                };

                return (
                  <option
                    key={item._id}
                    value={JSON.stringify(value)}
                    selected={isSelected}
                  >
                    {item.label}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div
          className={`time-line-data time-line-data-bloc-hide `}
          style={{ overflow: "inherit" }}
        >
          <div
            className={`time-line-filling-collection ${formattedLevelColor(Number(timeLine.filling_level), timeLine.limitFillingLevel, "reverse")}`}
          >
            <span
              className={`text-white ${formattedLevelColor(Number(timeLine.filling_level), timeLine.limitFillingLevel, "reverse")}`}
            >
              {timeLine.filling_level}%{" "}
            </span>
            <i
              className={`text-white hover:text-yellow button-action fas fa-eye ${formattedLevelColor(Number(timeLine.filling_level), timeLine.limitFillingLevel, "reverse")}`}
              onMouseOver={() => {
                setActiveWasteCategory(
                  wasteCategoryName !== ""
                    ? JSON.parse(wasteCategoryName).wasteCategoryName
                    : "",
                );
                setItemIndex(index);
              }}
            ></i>
          </div>
        </div>

        <div className="time-line-data time-line-data-large time-line-data-bloc-hide">
          {historyCycle && historyCycle.length > 0 && !loading && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <span className={weight && "font-bold text-gray-900"}>
                {timeLine.characterization
                  ? weight
                    ? weight
                    : toFrenchNumber(
                        weightToTonnes(
                          historyCycle[0].mass,
                          timeLine.weightUnit,
                        ),
                        1,
                      )
                  : toFrenchNumber(
                      weightToTonnes(historyCycle[0].mass, timeLine.weightUnit),
                      1,
                    )}
              </span>
              <span
                className={`ml-1 ${weight ? "font-bold text-gray-900" : "text-grey"}`}
              >
                {timeLine.characterization || weight
                  ? t("ton")
                  : timeLine.weightUnit === "kg"
                    ? "kg"
                    : t("ton")}

                {(weightToTonnes(historyCycle[0].mass, timeLine.weightUnit) >
                  1 ||
                  weight > 1) &&
                  "s"}
              </span>
              <i
                className="fas fa-info-circle ml-1"
                onMouseOver={() => setHelpWeight(true)}
                onMouseOut={() => setHelpWeight(false)}
              ></i>

              {helpWeight ? (
                <article
                  className="modal-item-key-help w-40 mt-20"
                  style={{ marginLeft: 650 }}
                >
                  {weight ? t("Weight reported") : t("Estimated weight")}
                </article>
              ) : null}
            </span>
          )}
        </div>

        <div className="time-line-data time-line-data-bloc-hide">
          {timeLine.packmatActive && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <img
                src={myPackmatIcon}
                alt="Packmat Icon"
                style={{ height: 23, verticalAlign: "middle", marginRight: 5 }}
              />
              <span className="text" style={{ marginLeft: 5 }}>
                {numberOfPackmat}
              </span>
            </span>
          )}
        </div>

        {index === itemIndex && (
          <ActivityTimeLineTooltip
            data={timeLine}
            setItemIndex={setItemIndex}
            activeWasteCategory={activeWasteCategory}
          />
        )}

        <ContainerModalClient
          data={timeLine}
          displayClient={displayClient}
          setDisplayClient={setDisplayClient}
          weightRef={weightRef}
          newClient={newClient}
          setNewClient={setNewClient}
          handleSelectionChange={handleSelectionChange}
          setClientCode={setClientCode}
          clientName={clientName}
          setClientName={setClientName}
          setClientAddress={setClientAddress}
          weight={weight}
          setWeight={setWeight}
          wasteCategoryName={wasteCategoryName}
          setWasteCategoryName={setWasteCategoryName}
          wasteCategory={wasteCategory}
          saveWeight={handleSubmit}
          totalWeight={totalWeight}
          superContainer={superContainers}
          refreshAction={fetchWasteCategory}
        />

        <div className={`time-line-data time-line-data-bloc-hide`}>
          <div className="w-full grid justify-items-center">
            <i
              className={`fas fa-eye hover:text-yellow cursor-pointer ${loading || (historyCycle && historyCycle.length < 1) ? "text-[#D0D0D0]" : "text-[#00A7A6]"}`}
              onClick={() => {
                if (!loading) {
                  setActiveWasteCategory(
                    wasteCategoryName !== ""
                      ? JSON.parse(wasteCategoryName).wasteCategoryName
                      : "",
                  );
                  setModalHistoryCycle(true);
                  setDataRow(timeLine);
                }
              }}
            ></i>
          </div>
        </div>

        <div className="time-line-data time-line-data-large time-line-data-bloc-hide">
          {timeLine.activatedSortingErrorOption && (
            <div className="flex flex-row ml-3">
              {loading || (historyCycle && historyCycle.length < 1) ? (
                <StarRatingIcon
                  numberOfStars={numberOfStars}
                  text={"."}
                  alignTextAboveStars={true}
                  color={"var(--gainsboro)"}
                  textSize={12}
                  iconSize={18}
                />
              ) : (
                <StarRatingIcon
                  numberOfStars={numberOfStars}
                  text={
                    numberOfAnomaly +
                    " " +
                    (numberOfAnomaly > 1
                      ? t("sorting_errors")
                      : t("sorting_error"))
                  }
                  alignTextAboveStars={true}
                  color={"grey"}
                  textSize={12}
                  iconSize={18}
                  percentage={
                    (totalVolumeSortingError * 100) / totalWasteVolume
                  }
                />
              )}
            </div>
          )}
        </div>

        <div className="time-line-data-bloc-row">
          <div className="time-line-data-bloc-row">
            <div className="visual-level">
              <div
                className={`${formattedLevelColor(timeLine.filling_level, timeLine.limitFillingLevel)}`}
                style={{
                  height: timeLine.filling_level + "%",
                  borderRadius: "0 0 2px 2px",
                }}
              ></div>
            </div>
            <span className="level">{timeLine.filling_level}% </span>
          </div>

          <div className="time-line-data ">
            <i className="fas fa-exclamation-triangle data-icon"></i>
          </div>
        </div>

        <div className="time-line-column-data time-line-data-large time-line-data-bloc-hide">
          <span className="time-line-data-bold">
            {formattedTypeContainer(timeLine.type)}
          </span>
          <span className="data-column">
            {timeLine.volume}
            {timeLine.volumeUnit}
          </span>
        </div>

        <ActivityCollectionFileModal
          data={timeLine}
          openFileModal={openFileModal}
          setOpenFileModal={setOpenFileModal}
          outputClient={outputClient}
          setOutputClient={setOutputClient}
          files={files}
          setFiles={setFiles}
          superContainers={superContainers}
          clientName={clientName}
          clientCode={clientCode}
          clientAddress={clientAddress}
        />

        <div className={`time-line-data time-line-data-bloc-hide`}>
          <div className="w-full grid justify-items-center">
            <i
              className={`fas fa-file-pdf text-3xl cursor-pointer ${files.length === 0 || loadScreen ? "text-gray-400" : "text-[#00A7A6]"}`}
              onClick={() => {
                if (!loadScreen) {
                  setOpenFileModal(true);
                }
              }}
            ></i>
          </div>
        </div>

        <div
          className={`time-line-data time-line-data-bloc-hide flex flex-row ${historyCycle && historyCycle.length > 0 && "cursor-pointer"}`}
        >
          <article className="data-table">
            <i
              className={` button-action ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times"}`}
              style={{
                color:
                  loadScreen || (historyCycle && historyCycle.length <= 0)
                    ? "#e5e7eb"
                    : "var(--akanthas-cyan)",
              }}
              onClick={() => {
                if (historyCycle && historyCycle.length > 0) {
                  setIsOpen(!isOpen);
                }
              }}
            ></i>
            <div
              className={`action-choice absolute w-48 right-14 ${isOpen ? "display-action" : ""}`}
            >
              <button
                className="action"
                onClick={() => {
                  if (historyCycle && historyCycle.length > 0) {
                    toggleDisplayWeight();
                  }
                  setIsOpen(!isOpen);
                }}
              >
                {t("Edit")}
              </button>
              <button
                className="action"
                onClick={() => {
                  if (historyCycle && historyCycle.length > 0) {
                    setDataRow(timeLine);
                    setActiveWasteCategory(
                      wasteCategoryName !== ""
                        ? JSON.parse(wasteCategoryName).wasteCategoryName
                        : "",
                    );
                    handleCsvExport(historyCycle, timeLine);
                  }
                  setIsOpen(!isOpen);
                }}
              >
                {t("Export")}
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default ActivityCollectionRow;
