import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Card } from "reactstrap";
import Loading from "../../components/utils/Loading";
import CameraRow from "./CameraRow";
import CameraFilter from "./CameraFilter";
import Pagination from "../../components/utils/Pagination";
import CameraHeader from "./CameraHeader";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const Camera = () => {
  const { t } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalEntry, setTotalEntry] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(50);
  const [resultPage, setResultPage] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [sortByField, setSortByField] = useState("name");
  const [orderSortByField, setOrderSortByField] = useState("1");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [dateBoundaries, setDateBoundaries] = useState([]);
  const [dateFilter, setDateFilter] = useState([0, 100]);
  const [batteryLevelFilter, setBatteryLevelFilter] = useState([0, 100]);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [stopDateFilter, setStopDateFilter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(`${API}cameras`, {
            headers: { authorization: `Bearer ${token}` },
            params: {
              all: false,
              keywordFilter: keywordFilter,
              sortByField: sortByField,
              orderSortByField: orderSortByField,
              batteryLevelFilter: batteryLevelFilter,
              startDateFilter: startDateFilter,
              stopDateFilter: stopDateFilter,
              page: currentPage,
              limit: recordPerPage,
            },
          }),
        ])
        .then(
          axios.spread((responseCameras) => {
            if (responseCameras.status === 200) {
              setTotalEntry(responseCameras.data.count);
              setTableData(responseCameras.data.dataCamerasList);
              setDateBoundaries(responseCameras.data.dateBoundaries);
              setIsLoading(false);
              setIsDeleted(false);
            }
          }),
        );
    };
    fetchData();
  }, [
    API,
    keywordFilter,
    totalEntry,
    resultPage,
    currentPage,
    recordPerPage,
    token,
    isDeleted,
    sortByField,
    orderSortByField,
    dateFilter,
    batteryLevelFilter,
    startDateFilter,
    stopDateFilter,
  ]);

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base">
        <h1>{t("My cameras")}</h1>

        <CameraFilter
          keywordFilter={keywordFilter}
          setKeywordFilter={setKeywordFilter}
          dateBoundaries={dateBoundaries}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          batteryLevelFilter={batteryLevelFilter}
          setBatteryLevelFilter={setBatteryLevelFilter}
          setStartDateFilter={setStartDateFilter}
          setStopDateFilter={setStopDateFilter}
          setDateBoundaries={setDateBoundaries}
        />
        <section className="top-table">
          <article className="operation-table">
            <div className="operation-data">
              <i className="fas fa-sort-amount-up ico-action "></i>
              <select
                className="sort-select"
                name="sortByField"
                onChange={(event) => setSortByField(event.target.value)}
              >
                <option value="name">{t("ID")}</option>
                <option value="lastScanDate">{t("Last update")}</option>
                <option value="batteryLevel">{t("Battery")}</option>
              </select>

              <select
                className="sort-select"
                name="orderSortByField"
                onChange={(event) => setOrderSortByField(event.target.value)}
              >
                <option value="1">{t("Ascending")}</option>
                <option value="-1">{t("Descending")}</option>
              </select>
            </div>
          </article>

          <Pagination
            totalEntry={totalEntry}
            recordPerPage={recordPerPage}
            setRecordPerPage={setRecordPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultPage={resultPage}
            setResultPage={setResultPage}
          />
        </section>

        <Card className="table">
          <div>
            <CameraHeader
              classColumnTable={
                "hidden lg:flex xl:flex 2xl:flex w-full justify-between bg-white rounded h-14 mx-0 my-2"
              }
            />
            <article className="content-table">
              {tableData.length > 0 ? (
                tableData.map((camera, index) => {
                  if (camera) {
                    return (
                      <Fragment key={camera._id}>
                        <CameraRow data={camera} />
                      </Fragment>
                    );
                  }
                  return null;
                })
              ) : (
                <section className={`error-form display-error`}>
                  <p>{t("No camera")}</p>
                </section>
              )}
            </article>
          </div>
          <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
        </Card>
      </div>
    );
  }
};

export default Camera;
