import { useTranslation } from "react-i18next";

const WasteHeader = ({ classColumnTable }) => {
  const { t } = useTranslation();
  return (
    <section className={classColumnTable}>
      <h6 className="w-1/6 font-semibold text-sm my-auto">
        {t("Waste Category")}
      </h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto">{t("Waste code")}</h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto mr-4">
        {t("UN transport code")}
      </h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto mr-4">
        {t("Density")} ( kg/m<sup>3</sup> )
      </h6>
      <h6 className="w-1/6 pr-10 font-semibold text-sm my-auto mr-4">
        {t("Treatment cost")} {`( €/_ )`}
      </h6>
      <h6 className="flex flex-col w-1/6 font-semibold text-sm my-auto mr-8">
        <span>{t("Unauthorized catergories")}</span>
        <span>{`(erreurs de tri)`}</span>
      </h6>
    </section>
  );
};

export default WasteHeader;
