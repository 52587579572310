import { useLocation } from "react-router-dom";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";

const AppNotificationsDetails = () => {
  const location = useLocation();
  const { title, description, date } = location.state || {};

  if (!location.state) {
    return <div>No notification data available.</div>;
  }

  return (
    <div>
      <AppHeaderBar title="Notification Details" />
      <div className="installation-detail-container">
        <h1>Notifications Details</h1>
        <p>Title: {title}</p>
        <p>Description: {description}</p>
        <p>Date: {date}</p>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppNotificationsDetails;
