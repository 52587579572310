import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "reactstrap";
import { ModalTitle } from "react-bootstrap";
import { useEffect, useState } from "react";
import Loading from "../../components/utils/Loading";
import {
  elapsedTimeContainer,
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import {
  formattedColorMaterial,
  formattedMaterial,
} from "../../components/formatted/FormattedText";
import DoughnutAndPieChart from "../../components/charts/DoughnutAndPieChart";
import { ImageCardComponent } from "../../components/imageCard/ImageCardComponent";
import ContainerCompact from "../container/ContainerCompact";
import { Dropdown } from "../../components/dropdownMenu/Dropdown";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ImageListAnomalies from "../../components/ImageListAnomalies";
import useWindowDimensions from "../../components/formatted/UseWindowsDimensions";

const ActivityCollectionModal = ({
  data,
  modalHistoryCycle,
  setModalHistoryCycle,
  exportDataToCSV,
  activeWasteCategory,
}) => {
  const { t, i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;

  const [anomalyDelivery, setAnomalyDelivery] = useState({});
  const [helpCycle, setHelpCycle] = useState(false);
  const [helpCompacting, sethelpCompacting] = useState(false);
  const [helpAnomaly, setHelpAnomaly] = useState(false);
  const [helpStarts, setHelpStarts] = useState(false);
  const [historyCycle, setHistoryCycle] = useState([]);
  const [dataSortedByAnomalies, setDataSortedByAnomalies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [historyId, setHistoryId] = useState(0);
  const [lastPackmatEfficiency, setLastPackmatEfficiency] = useState([]);
  const [numberOfAnomaly, setNumberOfAnomaly] = useState(0);
  const [numberOfStars, setNumberOfStars] = useState(3);
  const [numberOfPackmat, setNumberOfPackmat] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvDataReady, setCsvDataReady] = useState(false);
  const [totalVolumeSortingError, setTotalVolumeSortingError] = useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const collectionData = useSelector((state) => state.collection.data);
  const { widthWindow } = useWindowDimensions();
  let imageWidth = widthWindow < 1320 ? 220 : 140;
  let imageHeight = widthWindow < 1320 ? 220 : 140;

  let dataAnomalyDelivery = [];
  let labelsAnomalyDelivery = [];
  let colorAnomalyDelivery = [];

  const toggle = () => setModalHistoryCycle(!modalHistoryCycle);

  useEffect(
    () => {
      if (Object.keys(collectionData).length !== 0) {
        if (`${data._measurement}_${data._time}` in collectionData) {
          const collection =
            collectionData[`${data._measurement}_${data._time}`];
          setHistoryCycle(collection.historyCurrent);
          const filteredAndSortedData = collection.historyCurrent
            .filter((item) => item.anomaliesNewObject > 0)
            .sort((a, b) => b.anomaliesNewObject - a.anomaliesNewObject)
            .slice(0, 8)
            .sort((a, b) => new Date(b._time) - new Date(a._time));
          setDataSortedByAnomalies(filteredAndSortedData);
          setNumberOfAnomaly(collection.numberOfAnomaly);
          setNumberOfStars(collection.numberOfStars);
          setAnomalyDelivery(collection.anomalyDelivery);
          setNumberOfPackmat(collection.numberOfPackmat);
          setTotalVolumeSortingError(collection.totalVolumeSortingError);
          setTotalWasteVolume(collection.totalWasteVolume);
          setLastPackmatEfficiency(collection.lastPackmatEfficiency);
          prepareDataCSV(collection.historyCurrent);
          setIsLoading(false);
        }
      }
    },
    // eslint-disable-next-line
    [collectionData, data],
  );

  if (numberOfAnomaly) {
    dataAnomalyDelivery = Object.values(anomalyDelivery);
    for (const [key] of Object.entries(anomalyDelivery)) {
      labelsAnomalyDelivery.push(formattedMaterial(key, i18n.language));
      colorAnomalyDelivery.push(formattedColorMaterial(key));
    }
  } else {
    dataAnomalyDelivery.push(100);
    colorAnomalyDelivery.push("#9095a469");
    labelsAnomalyDelivery.push("Aucune anomalie");
  }

  const prepareDataCSV = (hostory) => {
    const label = [
      "Date",
      "Niveau",
      `Poids (${data.weightUnit === "t" ? "tonnes" : "kg"})`,
    ];
    const lastData = hostory.map((item) => [
      `${moment(item._time).utc().format("DD MM YYYY HH:mm")}`,
      `${item.filling_level}%`,
      data.weightUnit === "t" ? (item.mass / 1000).toFixed(2) : item.mass,
    ]);
    const csvContent = [label, ...lastData];
    setCsvData(csvContent);
    setCsvDataReady(true);
  };

  const findHistoryId = (targetTime) => {
    const index = historyCycle.findIndex((item) => item._time === targetTime);
    setHistoryId(index);
  };

  return (
    <Modal
      isOpen={modalHistoryCycle}
      toggle={toggle}
      className="modal-content-view"
    >
      <div className="container-modal-header">
        <div className="w-2/3">
          <ModalTitle>
            <div className="data-bold">
              <span>{data.addressName ? data.addressName : "-"} | </span>
              <span style={{ color: "var(--blush)" }}>
                {activeWasteCategory !== ""
                  ? activeWasteCategory
                  : data.wasteName
                    ? data.wasteName
                    : "-"}{" "}
              </span>
              <span>
                | {data.volume > 0 ? data.volume + data.volumeUnit : "-"}
              </span>
              {data?.positioning && data.positioning !== "" && (
                <>
                  <span> | </span>
                  <span style={{ color: "var(--gray)" }}>
                    {data.positioning}
                  </span>
                </>
              )}
            </div>
          </ModalTitle>
        </div>
        <div className="flex flex-row justify-between w-1/3">
          <div className="flex flex-end w-5/6">
            <Dropdown
              text={t("Export")}
              disabled={isLoading || !csvDataReady ? true : false}
              children={
                <>
                  <span
                    onClick={() => {
                      exportDataToCSV(csvData, data);
                    }}
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                  >
                    CSV (.csv)
                  </span>
                </>
              }
            />
          </div>
          <div className="flex flex-end w-1/6">
            <CloseIcon
              style={{
                color: "var(--gray)",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={() => setModalHistoryCycle(false)}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loading color="#efefeffb" />
      ) : (
        <div className="modal-nav-item">
          <section className="modal-item-key">
            <article className="modal-item-key-content">
              <div className="modal-item-key-title">
                <span>Cycle</span>
                <i
                  className="fa fa-info-circle"
                  onMouseOver={() => setHelpCycle(true)}
                  onMouseOut={() => setHelpCycle(false)}
                ></i>
              </div>
              <div className="modal-item-key-value">
                <h5>
                  {historyCycle.length > 0
                    ? elapsedTimeContainer(
                        historyCycle[historyCycle.length - 1]._time,
                        i18n.language,
                        false,
                        new Date(data._time),
                      )
                    : "-"}
                </h5>
                <div>
                  <span>
                    {t("Deposit")} :{" "}
                    {formattedDate(
                      historyCycle[historyCycle.length - 1]._time,
                      i18n.language,
                    )}
                  </span>
                  <span>
                    {t("Collection")} :{" "}
                    {formattedDate(data._time, i18n.language)}
                  </span>
                </div>
              </div>
              {helpCycle ? (
                <article className="modal-item-key-help">
                  {t("Number of days between deposit date and collection date")}
                  .
                </article>
              ) : null}
            </article>

            <article className="modal-item-key-content">
              <div className="modal-item-key-title">
                <span>{t("Number of sorting errors")}</span>
                <i
                  className="fa fa-info-circle"
                  onMouseOver={() => setHelpAnomaly(true)}
                  onMouseOut={() => setHelpAnomaly(false)}
                ></i>
              </div>
              <div className="modal-item-key-value">
                <h5>{data.activatedSortingErrorOption && numberOfAnomaly}</h5>
              </div>
              {helpAnomaly ? (
                <article className="modal-item-key-help">
                  {t(
                    "Anomalies are counted once, even if objects appear multiple times.",
                  )}
                </article>
              ) : null}
            </article>

            <article className="modal-item-key-content">
              <div className="modal-item-key-title">
                <span>{t("Waste category quality")}</span>
                <i
                  className="fa fa-info-circle"
                  onMouseOver={() => setHelpStarts(true)}
                  onMouseOut={() => setHelpStarts(false)}
                ></i>
              </div>
              <div className="flex flex-row modal-item-key-value">
                {data.activatedSortingErrorOption && (
                  <div className="flex flex-row w-full justify-between">
                    <article className="modal-item-key-star">
                      {numberOfStars === 3 ? (
                        <>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </>
                      ) : null}
                      {numberOfStars === 2 ? (
                        <>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                        </>
                      ) : null}
                      {numberOfStars === 1 ? (
                        <>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </>
                      ) : null}
                      {numberOfStars === 0 ? (
                        <>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </>
                      ) : null}
                    </article>
                    <span className="mt-4">
                      {(totalVolumeSortingError * 100) / totalWasteVolume
                        ? (totalVolumeSortingError * 100) / totalWasteVolume < 1
                          ? `< 1% ${t("of contaminants")}`
                          : `${Math.round(
                              (totalVolumeSortingError * 100) /
                                totalWasteVolume,
                            )}% ${t("of contaminants")}`
                        : "-"}
                    </span>
                  </div>
                )}
              </div>
              {helpStarts ? (
                <article className="modal-item-key-help">
                  {t(
                    "Assessment of material quality based on the volumetric proportion of detected errors",
                  )}
                  .
                  <p style={{ marginTop: 5, marginBottom: 0 }}>
                    <i style={{ fontSize: 15 }} className="far fa-star"></i>
                    <i style={{ fontSize: 15 }} className="far fa-star"></i>
                    <i
                      style={{ fontSize: 15 }}
                      className="far fa-star"
                    ></i>: {t("More than 30% of contaminants")}
                  </p>
                  <p style={{ marginTop: 5, marginBottom: 0 }}>
                    <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                    <i style={{ fontSize: 15 }} className="far fa-star"></i>
                    <i
                      style={{ fontSize: 15 }}
                      className="far fa-star"
                    ></i>: {t("Between 15% and 30% of contaminants")}
                  </p>
                  <p style={{ marginTop: 5, marginBottom: 0 }}>
                    <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                    <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                    <i
                      style={{ fontSize: 15 }}
                      className="far fa-star"
                    ></i>: {t("Between 5% and 15% of contaminants")}
                  </p>
                  <p style={{ marginTop: 5, marginBottom: 0 }}>
                    <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                    <i style={{ fontSize: 15 }} className="fas fa-star"></i>
                    <i
                      style={{ fontSize: 15 }}
                      className="fas fa-star"
                    ></i>: {t("Less than 5% of contaminants")}
                  </p>
                </article>
              ) : null}
            </article>

            {data.packmatActive ? (
              <article className="modal-item-key-content">
                <div className="modal-item-key-title">
                  <span>{t("Packmat")}</span>
                  <i
                    className="fa fa-info-circle"
                    onMouseOver={() => sethelpCompacting(true)}
                    onMouseOut={() => sethelpCompacting(false)}
                  ></i>
                </div>
                <div className="modal-item-key-value">
                  <h5>{numberOfPackmat}</h5>

                  {lastPackmatEfficiency ? (
                    <div>
                      <span>
                        {formattedDate(
                          lastPackmatEfficiency._time,
                          i18n.language,
                        )}{" "}
                        - {formattedTime(lastPackmatEfficiency._time)}
                      </span>

                      <span>
                        {t("Efficiency​")}​:{" "}
                        {lastPackmatEfficiency
                          ? lastPackmatEfficiency.efficiency
                          : "-"}
                        %
                      </span>
                    </div>
                  ) : null}
                </div>
                {helpCompacting ? (
                  <article className="modal-item-key-help">
                    {t(
                      "Number of packmat carried out since the arrival of the container on site and efficiency​ of the last packmat",
                    )}
                    .
                  </article>
                ) : null}
              </article>
            ) : null}
          </section>
          {dataSortedByAnomalies.length > 0 && (
            <ImageListAnomalies
              container={data}
              dataSortedByAnomalies={dataSortedByAnomalies}
              findHistoryId={findHistoryId}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          )}
          <div className="grid grid-cols-2 gap-3 md:grid-cols-1 sm:grid-cols-1 w-full">
            {data.activatedSortingErrorOption && (
              <article className="bg-white rounded-xl w-full truncate">
                <div className="modal-item-container-title">
                  <h5 className="data-bold modal-item-container-quality-current">
                    {t("Distribution of anomalies")}
                  </h5>
                </div>
                <div className="modal-item-chart modal-item-chart-pie">
                  <DoughnutAndPieChart
                    countTotal={numberOfAnomaly}
                    colorChart={colorAnomalyDelivery}
                    dataChart={dataAnomalyDelivery}
                    diameterCutout={0}
                    displayLegend={true}
                    labelChart={labelsAnomalyDelivery}
                    legendPosition="bottom"
                    subtextCenterPlugin=""
                    textCenterPlugin=""
                    titleTooltip={t("Number of sorting errors")}
                    type="pie"
                    activePluginChartLabel={false}
                  />
                </div>
              </article>
            )}
            <article className="bg-white rounded-xl w-full truncate">
              {historyCycle.length > 0 && (
                <ImageCardComponent
                  API={API}
                  container={data}
                  displayLegend={true}
                  expendImage={false}
                  setExpendImage={null}
                  history={historyCycle}
                  historyId={historyId}
                  isCharacterization={false}
                  setHistoryId={setHistoryId}
                  displayAnomalyLegend={false}
                  isActivity={true}
                  containerActivity={true}
                />
              )}
            </article>
            <article className="bg-white rounded-xl w-full truncate">
              <ContainerCompact
                container={data}
                history={true}
                dataForCompactOverlay={historyCycle}
                collection={true}
              />
            </article>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ActivityCollectionModal;
