import axios from "axios";
import { formattedError } from "../formatted/FormattedText";
import Cookies from "js-cookie";

/**
 * A routine to update form data according to user changes. The update relies
 * on the
 * -
 *
 * @param {dict} formData
 * @param {dict} setFormData
 */
export const handleFormChange = (event, formData, setFormData) => {
  let fieldValue;
  let secondvalue;

  const newFormData = { ...formData };
  const fieldName = event.target.name;
  const dictcategory = event.target.getAttribute("dictcategory");
  const indexarray = event.target.getAttribute("indexarray");
  const indexopening = event.target.getAttribute("indexopening");
  const secondfieldname = event.target.getAttribute("secondfield");

  if (
    event.target.type === "select" ||
    event.target.type === "select-one" ||
    event.target.type === "text" ||
    event.target.type === "tel" ||
    event.target.type === "time" ||
    event.target.type === "password"
  ) {
    fieldValue = event.target.value;
  }

  if (event.target.type === "number") {
    fieldValue = event.target.value ? parseFloat(event.target.value) : null;
  }

  if (
    event.target.getAttribute("type") === "checkbox" ||
    event.target.getAttribute("type") === "click"
  ) {
    fieldValue = event.target.checked;
  }

  if (secondfieldname && event.target.type === "select-one") {
    secondvalue =
      event.target.options[event.target.selectedIndex].getAttribute(
        "secondvalue",
      );
  }

  if (dictcategory) {
    if (indexopening && indexarray) {
      newFormData[dictcategory][fieldName][indexarray][indexopening] =
        fieldValue;
    } else {
      newFormData[dictcategory][fieldName] = fieldValue;
    }
  } else {
    newFormData[fieldName] = fieldValue;

    if (secondfieldname) {
      newFormData[secondfieldname] = secondvalue;
    }
  }

  setFormData(newFormData);
};

/**
 * A ... :
 * -
 *
 * @param {dict} ...
 */
export const handleOpenModalClick = (
  data,
  formData,
  setFormData,
  setIsModalOpen,
) => {
  if (!data?.displayColor) {
    data.displayColor = "#fff";
  }
  setFormData(data);
  setIsModalOpen(true);
};

/**
 * A ... :
 * -
 *
 * @param {dict} ...
 */
export const handleEditFormCancel = (setIsModalOpen) => {
  setIsModalOpen(false);
};

/**
 * A form submition routine to add a new entry to the table by executing :
 * - an API post request to send the data
 * - adding the entry to the current data array
 * - increasing (+1) the total number of entry
 * - cleaning the form
 *
 * @param {dict} addFormData Dictionnary with form data to store
 * @param {*}
 * @param {*}
 * @param {*}
 * @param {*}
 */
export const handleAddFormSubmit = async (
  event,
  addFormData,
  setAddFormData,
  defaultData,
  tableData,
  setTableData,
  totalEntry,
  setTotalEntry,
  path,
  setErrorForm,
  setSendingForm,
) => {
  event.preventDefault();

  let errorPassword = false;
  if (addFormData.password !== undefined) {
    if (addFormData.password.length < 6) {
      errorPassword = true;
      setErrorForm("Votre mot de passe doit contenir au moins 6 caractères");
    } else if (addFormData.password !== addFormData.passwordConfirm) {
      errorPassword = true;
      setErrorForm("Votre mot de passe doit être similaire");
    }
  }

  if (!errorPassword) {
    const API = process.env.REACT_APP_API_BACKEND;
    const token = Cookies.get("userToken");

    try {
      const response = await axios.post(`${API}${path}/add`, addFormData, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const newData = [...tableData, response.data];
        setTableData(newData);
        setTotalEntry(totalEntry + 1);
        setAddFormData(defaultData);
        return response;
      }
    } catch (error) {
      setSendingForm(false);
      setErrorForm(formattedError(error.response.data.error));
    }
  }
};

/**
 * A form submition routine to update an entry of the table by executing :
 * - an API post request to send the data
 * -
 * -
 * -
 *
 * @param {dict} editFormData Dictionnary with form data to store
 * @param {*}
 * @param {*}
 * @param {*}
 * @param {*}
 */
export const handleEditFormSubmit = async (editFormData, path) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  try {
    const response = await axios.post(
      `${API}${path}/update/${editFormData._id}`,
      editFormData,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};
