import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import WasteRow from "./WasteRow";
import WasteEdit from "./WasteEdit";
import WasteFilter from "./WasteFilter";
import WasteCreate from "./WasteCreate";
import Pagination from "../../components/utils/Pagination";
import WasteHeader from "./WasteHeader";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const Waste = ({ token, API }) => {
  const defaultData = {
    hazardous: false,
    value: "",
    label: "",
    unCodeFull: "",
    unCodeEmpty: "",
    wasteCodeFull: "",
    wasteCodeEmpty: "",
    transportCost: parseInt(0),
    treatmentCostFull: parseInt(0),
    treatmentCostEmpty: parseInt(0),
    treatmentCostUnit: "kilogram",
    density: parseInt(0),
    displayColor: "#fff",
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const wasteId = searchParams.get("wasteId");
  const openEdit = searchParams.get("openEdit");
  const openCreate = searchParams.get("openCreate");
  const sortingErrors = searchParams.get("sortingErrors");
  const { t } = useTranslation();
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalEntry, setTotalEntry] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [resultPage, setResultPage] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [listWasteCodeFullFilter, setListWasteCodeFullFilter] = useState({});
  const [wasteCodeFullKeys, setWasteCodeFullKeys] = useState([""]);
  const [addFormData, setAddFormData] = useState(defaultData);
  const [formData, setFormData] = useState(defaultData);
  const [modalCreate, setModalCreate] = useState(openCreate ? true : false);
  const [sortByField, setSortByField] = useState("label");
  const [orderSortByField, setOrderSortByField] = useState("1");
  const [modalEdit, setModalEdit] = useState(openEdit ? true : false);
  const [addInfo, setAddInfo] = useState(false);

  const unitList = [
    { label: "litre", value: "litre" },
    { label: "kg", value: "kilogram" },
    { label: "tonne", value: "ton" },
    { label: "m3", value: "cubic_meter" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${API}wastecategory?page=${currentPage}&limit=${recordPerPage}`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
          params: {
            keywordFilter: keywordFilter,
            listUnCodeFullFilter: {},
            listUnCodeEmptyFilter: {},
            listWasteCodeFullFilter: listWasteCodeFullFilter,
            listWasteCodeEmptyFilter: {},
            sortByField: sortByField,
            orderSortByField: orderSortByField,
          },
        },
      );
      const wasteFind =
        response.data.data.find((waste) => waste._id === wasteId) || {};

      setResultPage(Math.ceil(response.data.count / recordPerPage));
      setTotalEntry(response.data.count);
      setTableData(response.data.data);
      setWasteCodeFullKeys(response.data.wasteCodeFullKeys);
      setFormData(wasteFind);

      setIsLoading(false);
      setIsDeleted(false);
    };
    fetchData();
  }, [
    token,
    API,
    totalEntry,
    resultPage,
    currentPage,
    recordPerPage,
    listWasteCodeFullFilter,
    isDeleted,
    keywordFilter,
    sortByField,
    orderSortByField,
    wasteId,
  ]);

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base" key={"waste"}>
        <h1>{t("My material catergories")}</h1>

        <WasteFilter
          keywordFilterDisplayOptions={(tableData || []).map(
            (waste) => waste.label,
          )}
          keywordFilter={keywordFilter}
          setKeywordFilter={setKeywordFilter}
          wasteCodeFullKeys={wasteCodeFullKeys}
          setListWasteCodeFullFilter={setListWasteCodeFullFilter}
        />

        <section className="top-table">
          <article className="operation-table">
            <div className="flex relative">
              <button
                className="operation-data operation-button my-auto"
                onClick={() => setModalCreate(true)}
                onMouseOver={() => setAddInfo(true)}
                onMouseOut={() => setAddInfo(false)}
              >
                <i className="fas fa-plus-circle icon-header"></i>
              </button>
              {addInfo && (
                <span className="absolute bg-yellow text-white -right-32 px-2 rounded -top-5">
                  {t("Add a category")}
                </span>
              )}
            </div>

            <div className="operation-data">
              <i className="fas fa-sort-amount-up ico-action "></i>

              <select
                className="sort-select"
                name="sortByField"
                onChange={(event) => setSortByField(event.target.value)}
              >
                <option value="label">{t("Waste Category")}</option>
                <option value="wasteCodeFull">{t("Waste code")}</option>
                <option value="treatmentCostFull">{t("Cost")}</option>
              </select>

              <select
                className="sort-select"
                name="orderSortByField"
                onChange={(event) => setOrderSortByField(event.target.value)}
              >
                <option value="1">{t("Ascending")}</option>
                <option value="-1">{t("Descending")}</option>
              </select>
            </div>
          </article>

          <Pagination
            totalEntry={totalEntry}
            recordPerPage={recordPerPage}
            setRecordPerPage={setRecordPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultPage={resultPage}
            setResultPage={setResultPage}
          />
        </section>

        <div className="w-full mb-2 text-black">
          <WasteHeader
            classColumnTable={
              "hidden lg:flex xl:flex 2xl:flex w-full justify-between bg-white rounded h-14 mx-0 my-2 px-2"
            }
          />

          <section className="flex flex-col">
            {(tableData || []).map((waste, index) => {
              return (
                <Fragment key={waste._id}>
                  {modalEdit && formData._id === waste._id ? (
                    <WasteEdit
                      key={`edit-${waste._id}`}
                      tableData={tableData}
                      setTableData={setTableData}
                      editFormData={formData}
                      setEditFormData={setFormData}
                      unitList={unitList}
                      modalEdit={modalEdit}
                      setModalEdit={setModalEdit}
                      sortingErrors={sortingErrors}
                    />
                  ) : null}
                  <WasteRow
                    data={waste}
                    tableData={tableData}
                    setTableData={setTableData}
                    setIsDeleted={setIsDeleted}
                    formData={formData}
                    setFormData={setFormData}
                    setModalEdit={setModalEdit}
                    unitList={unitList}
                  />
                </Fragment>
              );
            })}

            {modalCreate && (
              <WasteCreate
                unitList={unitList}
                addFormData={addFormData}
                setAddFormData={setAddFormData}
                defaultData={defaultData}
                tableData={tableData}
                setTableData={setTableData}
                totalEntry={totalEntry}
                setTotalEntry={setTotalEntry}
                modalCreate={modalCreate}
                setModalCreate={setModalCreate}
              />
            )}
          </section>
        </div>
        <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
      </div>
    );
  }
};

export default Waste;
