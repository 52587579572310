import { handleSelectAll } from "./utilsTable";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomPopover from "../../components/popover/CustomPopover";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ContainerHeader = ({
  classColumnTable,
  data,
  setData,
  setContainersSelected,
  selectedAll,
  screenWidth,
  displayToBlock,
  noActivePackmat,
}) => {
  const [popoverContent, setPopoverContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handlePopoverOpen = (event, content) => {
    setPopoverContent(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classColumnTable}>
      <input
        type="checkbox"
        className="my-auto w-4 h-4 cursor-pointer"
        onChange={(event) =>
          handleSelectAll(event, data, setData, setContainersSelected)
        }
        checked={selectedAll}
      />
      {screenWidth < 1280 ? (
        <></>
      ) : displayToBlock ? (
        <></>
      ) : (
        <>
          <Box sx={{ flexGrow: 1, marginTop: 2.5 }}>
            <Grid container columns={{ xs: 10 }}>
              <Grid item xs={1.3}></Grid>

              <Grid item xs={1}>
                <h6 className="font-semibold text-sm">{t("Last image")}</h6>
              </Grid>
              <Grid item xs={1.2}>
                <h6 className="font-semibold text-sm">{t("Address")}</h6>
              </Grid>
              <Grid item xs={1}>
                <h6 className="font-semibold text-sm">{t("Filling level")}</h6>
              </Grid>
              <Grid item xs={0.7}>
                <h6 className="font-semibold text-sm">{t("Weight")}</h6>
              </Grid>

              {!noActivePackmat && (
                <Grid item xs={0.5}>
                  <h6
                    className="font-semibold w-16 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
                    onMouseEnter={(event) =>
                      handlePopoverOpen(event, t("Packmat"))
                    }
                    onMouseLeave={handlePopoverClose}
                  >
                    {t("Packmat")}
                  </h6>
                </Grid>
              )}

              <Grid item xs={0.9}>
                <h6 className="font-semibold text-sm whitespace-nowrap overflow-hidden text-ellipsis ml-3">
                  {t("Quality")}
                </h6>
              </Grid>
              <Grid item xs={0.8}>
                <h6 className="font-semibold text-sm">{t("Area")}</h6>
              </Grid>

              <Grid item xs={0.8}>
                <h6
                  className="font-semibold w-20 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, t("Prediction Max"))
                  }
                  onMouseLeave={handlePopoverClose}
                >
                  {t("Prediction Max")}
                </h6>
              </Grid>

              <Grid item xs={0.5}>
                <h6
                  className="font-semibold w-18 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, t("Suggested Actions Beta"))
                  }
                  onMouseLeave={handlePopoverClose}
                >
                  {t("Suggested Actions Beta")}
                </h6>
              </Grid>

              <Grid item xs={1}>
                <h6 className="font-semibold text-sm ml-1">{t("Status")}</h6>
              </Grid>
            </Grid>
          </Box>
          <CustomPopover
            open={open}
            anchorEl={anchorEl}
            content={popoverContent}
            onClose={handlePopoverClose}
          />
        </>
      )}
    </div>
  );
};

export default ContainerHeader;
