import { StyleSheet, Font } from "@react-pdf/renderer";
import MulishBlack from "../../../static/fonts/Mulish/Mulish-Black.ttf";

Font.register({
  family: "Mulish",
  fonts: [{ src: MulishBlack, fontWeight: 900 }],
});

export const wasteQualityStyleSheet = StyleSheet.create({
  chartContent: {
    padding: 20,
    flexDirection: "row",
  },

  volumeContent: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: -20,
    flexDirection: "row",
  },

  materialContent: {
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "row",
  },

  tableContent: {
    padding: 20,
    flexDirection: "row",
  },

  ChartTitle: {
    fontSize: 18,
    color: "#00A7A6",
    fontFamily: "Mulish",
    fontWeight: 1000,
    letterSpacing: "1px",
  },

  stats: {
    fontSize: 18,
    fontFamily: "Mulish",
    color: "#e81d7a",
    fontWeight: 1000,
    marginTop: 50,
  },

  text: {
    fontSize: 12,
    fontFamily: "Mulish",
  },

  textSmall: {
    fontSize: 8,
    fontFamily: "Mulish",
  },

  legendText: {
    fontSize: 9,
    fontFamily: "Mulish",
  },

  image: {
    flex: 7,
    alignItems: "flex-end",
    marginLeft: 50,
    height: "300px",
  },

  materialChart: {
    flex: 5,
    marginLeft: -20,
    paddingRight: 20,
    height: "40vh",
  },

  caracterisationLogo: {
    width: 28,
    height: 38,
    marginRight: 2,
  },

  caracterisationAndStats: {
    marginVertical: 3,
    height: "25%",
    width: "100%",
    flexDirection: "row",
  },
  caracterisationContainer: {
    fontSize: 26,
    fontFamily: "Mulish",
    color: "#e81d7a",
    fontWeight: 500,
  },
  caracterisationText: {
    fontSize: 12,
    fontFamily: "Mulish",
    color: "#0F265C",
    fontWeight: 500,
  },

  noticeText: {
    width: "100%",
    flexDirection: "row",
  },

  notice: {
    fontSize: 14,
    fontFamily: "Mulish",
    color: "#e81d7a",
    marginRight: 1,
    marginTop: -3,
    fontWeight: 500,
  },
});
